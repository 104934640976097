/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: SidebarCategoriesDisplay.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. SidebarCategoriesDisplay.js
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
import React, { useContext, useState } from 'react';
import { Context } from "../index";
// import './ECG/ECG_Categories_CollapseDisplay.css';
// import './ECG/ECG_Categories_ButtonDisplay.css';
import { useNavigate, useLocation } from 'react-router-dom';
import * as consts from '../utils/consts';
import { useTranslation } from 'react-i18next';
import { Badge } from 'react-bootstrap';
import './SidebarCategoriesDisplay.css';

import { FetchTable, MakeTree } from '../utils/PageDataPrepare';

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'instant' });
};

//  Элемент с подписью, обозначающей, что в этой категории есть демо кейсы
const HasDemoLabel = ({ t }) => {
    return <span className='badge rounded-pill text-bg-primary fw-normal'>{t('Demo_short').toLocaleLowerCase()}</span>;
};

const SidebarCategoriesDisplay = ({ categories, matchingCategories = [], forParent = null }) => {
    const { user } = useContext(Context);
    const { pathname } = useLocation();
    const [pimuCategory, categoryName] = pathname.substring(1).split('/');
    const history = useNavigate();
    const { t } = useTranslation();
    const [isSidebarVisible, setSidebarVisible] = useState(false);

    const NavigateToSubCategory = (categoryId) => {
        scrollToTop();
        user.gender = null;
        user.setSearch(null);
        history(`${FetchTable[categoryName].route}/${categoryId}?page=1&gender=3`);

        return;
    };

    if (!categories)
        return;

    let categoriesArray = categories instanceof Array ? categories : Object.keys(categories).map((key) => categories[key]);

    //  Привести объекты массива категорий к одному типу.
    categoriesArray = categoriesArray.filter((category) => typeof category == "object").map((category) => { return { ...category, sublevel: category?.sublevel === undefined ? 0 : category.sublevel }; }).sort((a, b) => a.position > b.position);

    //  Преобразовать обычный список записей в структурированный древообразный вид.
    //  Будут выделены "корневые" категории и для каждой из них будет сформирован список потомков и так до конца.
    let categoriesTree = MakeTree(categoriesArray, matchingCategories);

    //  Это отфильтрует список категорий и оставит лишь те, что подходят к указанному id.
    if (forParent !== null)
        categoriesTree = categoriesTree.filter( ({ id }) => id == forParent );

    if (matchingCategories && matchingCategories?.length)
        categoriesTree = categoriesTree.filter( ({ searched }) => searched === true );

    return (
        <div>
            <button 
                className="sidebar-toggle" 
                onClick={() => setSidebarVisible(!isSidebarVisible)}
            >
                {isSidebarVisible ? 'Скрыть ▲' : 'Показать подкатегории ▼'}
            </button>
            <div className={`card_list ${isSidebarVisible ? 'visible' : 'hidden'}`}>
                <CategoriesTree items={categoriesTree} user={user} t={t} NavigateToSubCategory={NavigateToSubCategory} />
            </div>
        </div>
    );
};

const CategoriesTree = ({ items, user, t, NavigateToSubCategory, level = 0 }) => {
    const [displayChildren, setDisplayChildren] = useState({});

    const toggleChildren = (categoryId) => {
        setDisplayChildren((prevState) => ({
            ...prevState,
            [categoryId]: !prevState[categoryId]
        }));
    };

    return items.map((category) => {
        if (!category || !category.id) return null;

        const hasChildren = category.sublevels_count > 0;

        return (
            <div className='card_flex' key={category.id}>
                <button
                    className={`card_items ${displayChildren[category.id] && hasChildren ? 'active' : ''}`}
                    data-has-children={hasChildren}
                    data-level={level}
                    title={hasChildren ? "" : "Перейти к кейсам"}
                    onClick={() => hasChildren ? toggleChildren(category.id) : NavigateToSubCategory(category.id)}
                >
                    <div className='card-item-name'>
                        <div className='row'>
                            <div className='col-xl'>{category['name_' + user.language]}</div>
                            <div className='col-auto'>
                                {user?.access_level?.demo_access && Number(category.totalDemoCases) > 0 && (
                                    <HasDemoLabel t={t} />
                                )}
                            </div>
                        </div>
                    </div>
                </button>
                {displayChildren[category.id] && category.children && (
                    <div className='subcategories_container'>
                        <CategoriesTree
                            items={category.children}
                            user={user}
                            t={t}
                            NavigateToSubCategory={NavigateToSubCategory}
                            level={level + 1}
                        />
                    </div>
                )}
            </div>
        );
    });
};

export default SidebarCategoriesDisplay;
